.clock {
    font-size: 2em;
    border-radius: 4px;
}

.clock > span {
}

.clock > span::after {
    padding: 6px;
    content: ':'
}

.clock > span:last-child::after {
    content: ''
}