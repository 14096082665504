.small {
    width: 50px;
}

.medium {
    width: 75px;
}

.large {
    width: 100px;
}
