@tailwind base;
@tailwind components;
@tailwind utilities;


.lined {
    display: flex;
    flex-direction: row;
}

.lined:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgba(65, 65, 65, 0.2);
    margin: auto auto auto 5px;
}
