.user_preview {
    width: 300px;
    display: grid;
    grid-template-areas: 'image info';
    grid-column-gap: 16px;
    justify-content: start;
    height: 64px;
}

.user_preview img {
    max-width: 64px;
    grid-area: image;
}

.content {
    grid-area: info;
    font-size: 1em;
}

.content a {
    color: inherit;
    text-decoration: none;
}

.content a:hover {
    color: blue;
}

.content .rating {
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    font-size: 0.7em;
    color: gray;
}

.username {
    display: inline-block;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: bottom;
    white-space: nowrap;
}
